import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { GetArticleQuery } from '../../graphql-types';
import { MetaData, UserContent } from '../components';

type Props = {
	data: GetArticleQuery;
};

const Container = styled.div`
	min-height: 85vh;
	font-family: ${(props) => props.theme.font.primary};
`;

const ArticlePage: React.FC<Props> = (props) => {
	const article = props?.data?.startupClubArticles;
	const userName = `${article?.user?.first} ${article?.user?.last}`;
	const minText = (article?.read_time ?? 0) > 1 ? 'mins' : 'min';
	return (
		<React.Fragment>
			<MetaData
				title={article?.title ?? ''}
				description={article?.excerpt ?? ''}
			/>

			<Container className="w-container w-container--padded">
				{article?.images?.full ? (
					<div className={'article-main-img'}>
						<img src={article?.images?.full} />
					</div>
				) : null}

				<h2
					className="articleheader center"
					dangerouslySetInnerHTML={{ __html: article?.title ?? '' }}
				/>
				<h4 className="center readtime">
					{article?.read_time} {minText} | {article?.date} | {userName}
				</h4>
				<div className="w-row">
					<div className="w-col w-col-12">
						<UserContent content={article?.content ?? ''} />
					</div>
				</div>
			</Container>
		</React.Fragment>
	);
};

export const query = graphql`
	query getArticle($slug: String!) {
		startupClubArticles(slug: { eq: $slug }) {
			id
			title
			excerpt
			read_time
			date(formatString: "DD/MM/YYYY")

			user {
				first
				last
				profile_picture
			}
			images {
				full
			}
			content
		}
	}
`;

export default ArticlePage;
